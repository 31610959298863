define("discourse/plugins/chat/discourse/components/chat/navbar/title", ["exports", "@ember/helper", "discourse/helpers/concat-class", "discourse-common/helpers/d-icon", "discourse/plugins/chat/discourse/components/chat/navbar/sub-title", "@ember/template-factory", "@ember/component", "@ember/component/template-only"], function (_exports, _helper, _concatClass, _dIcon, _subTitle, _templateFactory, _component, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ChatNavbarTitle = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <div
      title={{@title}}
      class={{concatClass "c-navbar__title" (if @showFullTitle "full-title")}}
    >
      {{#if (has-block)}}
        <span class="c-navbar__title-text">{{if @icon (icon @icon)}}
          {{@title}}</span>
        {{yield (hash SubTitle=SubTitle)}}
      {{else}}
        <span class="c-navbar__title-text">{{if
            @icon
            (icon @icon)
          }}{{@title}}</span>
      {{/if}}
    </div>
  
  */
  {
    "id": "GVm4Kshb",
    "block": "[[[1,\"\\n  \"],[10,0],[15,\"title\",[30,1]],[15,0,[28,[32,0],[\"c-navbar__title\",[52,[30,2],\"full-title\"]],null]],[12],[1,\"\\n\"],[41,[48,[30,4]],[[[1,\"      \"],[10,1],[14,0,\"c-navbar__title-text\"],[12],[1,[52,[30,3],[28,[32,1],[[30,3]],null]]],[1,\"\\n        \"],[1,[30,1]],[13],[1,\"\\n      \"],[18,4,[[28,[32,2],null,[[\"SubTitle\"],[[32,3]]]]]],[1,\"\\n\"]],[]],[[[1,\"      \"],[10,1],[14,0,\"c-navbar__title-text\"],[12],[1,[52,[30,3],[28,[32,1],[[30,3]],null]]],[1,[30,1]],[13],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"]],[\"@title\",\"@showFullTitle\",\"@icon\",\"&default\"],false,[\"if\",\"has-block\",\"yield\"]]",
    "moduleName": "/app/code/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/navbar/title.js",
    "scope": () => [_concatClass.default, _dIcon.default, _helper.hash, _subTitle.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)());
  var _default = _exports.default = ChatNavbarTitle;
});