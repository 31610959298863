define("discourse/plugins/chat/discourse/components/chat-emoji-avatar", ["exports", "discourse/helpers/replace-emoji", "@ember/template-factory", "@ember/component", "@ember/component/template-only"], function (_exports, _replaceEmoji, _templateFactory, _component, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ChatEmojiAvatar = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <div class="chat-emoji-avatar">
      <div class="chat-emoji-avatar-container">
        {{replaceEmoji @emoji}}
      </div>
    </div>
  
  */
  {
    "id": "ThiFSmfy",
    "block": "[[[1,\"\\n  \"],[10,0],[14,0,\"chat-emoji-avatar\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"chat-emoji-avatar-container\"],[12],[1,\"\\n      \"],[1,[28,[32,0],[[30,1]],null]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[\"@emoji\"],false,[]]",
    "moduleName": "/app/code/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat-emoji-avatar.js",
    "scope": () => [_replaceEmoji.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)());
  var _default = _exports.default = ChatEmojiAvatar;
});