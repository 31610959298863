define("discourse/plugins/chat/discourse/components/chat/message-creator/index", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "discourse/plugins/chat/discourse/components/chat/message-creator/add-members", "discourse/plugins/chat/discourse/components/chat/message-creator/constants", "discourse/plugins/chat/discourse/components/chat/message-creator/new-group", "discourse/plugins/chat/discourse/components/chat/message-creator/search", "@ember/template-factory", "@ember/component"], function (_exports, _component, _tracking, _object, _addMembers, _constants, _newGroup, _search, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _class2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let ChatMessageCreator = _exports.default = (_class = (_class2 = class ChatMessageCreator extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "mode", _descriptor, this);
      _initializerDefineProperty(this, "members", _descriptor2, this);
    }
    get componentForMode() {
      switch (this.args.mode ?? this.mode) {
        case _constants.MODES.search:
          return _search.default;
        case _constants.MODES.new_group:
          return _newGroup.default;
        case _constants.MODES.add_members:
          return _addMembers.default;
      }
    }
    changeMode(mode1) {
      let members1 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
      this.mode = mode1;
      this.changeMembers(members1);
    }
    changeMembers(members1) {
      this.members = members1;
    }
    cancelAction() {
      return this.args.onCancel?.() || this.changeMode(_constants.MODES.search);
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <div class="chat-message-creator-container">
        <div class="chat-message-creator">
          <this.componentForMode
            @channel={{@channel}}
            @onChangeMode={{this.changeMode}}
            @onChangeMembers={{this.changeMembers}}
            @close={{@onClose}}
            @cancel={{this.cancelAction}}
            @members={{this.members}}
          />
        </div>
      </div>
    
  */
  {
    "id": "7sc5Y6jt",
    "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"chat-message-creator-container\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"chat-message-creator\"],[12],[1,\"\\n        \"],[8,[30,0,[\"componentForMode\"]],null,[[\"@channel\",\"@onChangeMode\",\"@onChangeMembers\",\"@close\",\"@cancel\",\"@members\"],[[30,1],[30,0,[\"changeMode\"]],[30,0,[\"changeMembers\"]],[30,2],[30,0,[\"cancelAction\"]],[30,0,[\"members\"]]]],null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@channel\",\"@onClose\"],false,[]]",
    "moduleName": "/app/code/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/message-creator/index.js",
    "isStrictMode": true
  }), _class2), _class2), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "mode", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return _constants.MODES.search;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "members", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "changeMode", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "changeMode"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeMembers", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "changeMembers"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancelAction", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "cancelAction"), _class.prototype)), _class);
});