define("discourse/plugins/chat/discourse/components/chat/modal/toggle-channel-status", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "@ember/template", "discourse/components/d-button", "discourse/components/d-modal", "discourse/lib/ajax-error", "discourse-common/helpers/i18n", "discourse-i18n", "discourse/plugins/chat/discourse/models/chat-channel", "@ember/template-factory", "@ember/component"], function (_exports, _component, _object, _service, _template, _dButton, _dModal, _ajaxError, _i18n, _discourseI18n, _chatChannel, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _class2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let ChatModalToggleChannelStatus = _exports.default = (_class = (_class2 = class ChatModalToggleChannelStatus extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "chatApi", _descriptor, this);
      _initializerDefineProperty(this, "router", _descriptor2, this);
    }
    get channel() {
      return this.args.model;
    }
    get buttonLabel() {
      if (this.channel?.isClosed) {
        return "chat.channel_settings.open_channel";
      } else {
        return "chat.channel_settings.close_channel";
      }
    }
    get instructions() {
      if (this.channel?.isClosed) {
        return (0, _template.htmlSafe)(_discourseI18n.default.t("chat.channel_open.instructions"));
      } else {
        return (0, _template.htmlSafe)(_discourseI18n.default.t("chat.channel_close.instructions"));
      }
    }
    get modalTitle() {
      if (this.channel?.isClosed) {
        return "chat.channel_open.title";
      } else {
        return "chat.channel_close.title";
      }
    }
    onStatusChange() {
      const status1 = this.channel.isClosed ? _chatChannel.CHANNEL_STATUSES.open : _chatChannel.CHANNEL_STATUSES.closed;
      return this.chatApi.updateChannelStatus(this.channel.id, status1).then(() => {
        this.args.closeModal();
        this.router.transitionTo("chat.channel", ...this.channel.routeModels);
      }).catch(_ajaxError.popupAjaxError);
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <DModal
        @closeModal={{@closeModal}}
        class="chat-modal-toggle-channel-status"
        @inline={{@inline}}
        @title={{i18n this.modalTitle}}
      >
        <:body>
          <p
            class="chat-modal-toggle-channel-status__instructions"
          >{{this.instructions}}</p>
        </:body>
        <:footer>
          <DButton
            @action={{this.onStatusChange}}
            @label={{this.buttonLabel}}
            id="chat-channel-toggle-btn"
            class="btn-primary"
          />
        </:footer>
      </DModal>
    
  */
  {
    "id": "RhGUTAYT",
    "block": "[[[1,\"\\n    \"],[8,[32,0],[[24,0,\"chat-modal-toggle-channel-status\"]],[[\"@closeModal\",\"@inline\",\"@title\"],[[30,1],[30,2],[28,[32,1],[[30,0,[\"modalTitle\"]]],null]]],[[\"body\",\"footer\"],[[[[1,\"\\n        \"],[10,2],[14,0,\"chat-modal-toggle-channel-status__instructions\"],[12],[1,[30,0,[\"instructions\"]]],[13],[1,\"\\n      \"]],[]],[[[1,\"\\n        \"],[8,[32,2],[[24,1,\"chat-channel-toggle-btn\"],[24,0,\"btn-primary\"]],[[\"@action\",\"@label\"],[[30,0,[\"onStatusChange\"]],[30,0,[\"buttonLabel\"]]]],null],[1,\"\\n      \"]],[]]]]],[1,\"\\n  \"]],[\"@closeModal\",\"@inline\"],false,[]]",
    "moduleName": "/app/code/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/modal/toggle-channel-status.js",
    "scope": () => [_dModal.default, _i18n.default, _dButton.default],
    "isStrictMode": true
  }), _class2), _class2), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "chatApi", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "onStatusChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onStatusChange"), _class.prototype)), _class);
});