define("discourse/plugins/chat/discourse/components/chat-channel-metadata", ["exports", "@glimmer/component", "I18n", "discourse/plugins/chat/discourse/components/chat-channel-unread-indicator", "@ember/template-factory", "@ember/component"], function (_exports, _component, _I18n, _chatChannelUnreadIndicator, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  class ChatChannelMetadata extends _component.default {
    get unreadIndicator() {
      return this.args.unreadIndicator ?? false;
    }
    get lastMessageFormattedDate() {
      return moment(this.args.channel.lastMessage.createdAt).calendar(null, {
        sameDay: "LT",
        lastDay: `[${_I18n.default.t("chat.dates.yesterday")}]`,
        lastWeek: "dddd",
        sameElse: "l"
      });
    }
  }
  _exports.default = ChatChannelMetadata;
  _class = ChatChannelMetadata;
  (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <div class="chat-channel__metadata">
        {{#if @channel.lastMessage}}
          <div class="chat-channel__metadata-date">
            {{this.lastMessageFormattedDate}}
          </div>
        {{/if}}
  
        {{#if this.unreadIndicator}}
          <ChatChannelUnreadIndicator @channel={{@channel}} />
        {{/if}}
      </div>
    
  */
  {
    "id": "nfhIIGgc",
    "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"chat-channel__metadata\"],[12],[1,\"\\n\"],[41,[30,1,[\"lastMessage\"]],[[[1,\"        \"],[10,0],[14,0,\"chat-channel__metadata-date\"],[12],[1,\"\\n          \"],[1,[30,0,[\"lastMessageFormattedDate\"]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"unreadIndicator\"]],[[[1,\"        \"],[8,[32,0],null,[[\"@channel\"],[[30,1]]],null],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"@channel\"],false,[\"if\"]]",
    "moduleName": "/app/code/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat-channel-metadata.js",
    "scope": () => [_chatChannelUnreadIndicator.default],
    "isStrictMode": true
  }), _class);
});