define("discourse/plugins/chat/discourse/components/chat/message-creator/members", ["exports", "@glimmer/component", "@ember/component", "@ember/modifier", "@ember/object", "@ember/render-modifiers/modifiers/did-insert", "discourse-common/helpers/d-icon", "discourse-i18n", "truth-helpers/helpers/eq", "discourse/plugins/chat/discourse/components/chat/message-creator/lib/iterate-list", "discourse/plugins/chat/discourse/components/chat/message-creator/member", "@ember/template-factory"], function (_exports, _component, _component2, _modifier, _object, _didInsert, _dIcon, _discourseI18n, _eq, _iterateList, _member, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _class2;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  let Members = _exports.default = (_class = (_class2 = class Members extends _component.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "addMoreMembersLabel", _discourseI18n.default.t("chat.new_message_modal.user_search_placeholder"));
    }
    onFilter() {
      this.args.onFilter(...arguments);
    }
    registerFocusFilterAction(element1) {
      this.args.registerFocusFilterAction(() => element1.focus());
    }
    handleKeypress(event1) {
      if (event1.key === "Backspace" && event1.target.value === "") {
        event1.preventDefault();
        event1.stopPropagation();
        if (!this.args.highlightedMember) {
          this.args.onHighlightMember(this.args.members.lastObject);
        } else {
          this.args.onSelectMember(this.args.highlightedMember);
        }
        return;
      }
      if (event1.key === "ArrowLeft" && event1.target.value === "") {
        event1.preventDefault();
        event1.stopPropagation();
        this.args.onHighlightMember((0, _iterateList.getPrevious)(this.args.members, this.args.highlightedMember));
        return;
      }
      if (event1.key === "ArrowRight" && event1.target.value === "") {
        event1.preventDefault();
        event1.stopPropagation();
        this.args.onHighlightMember((0, _iterateList.getNext)(this.args.members, this.args.highlightedMember));
        return;
      }
      if (event1.key === "Enter" && this.args.highlightedMember) {
        event1.preventDefault();
        event1.stopPropagation();
        this.args.onSelectMember(this.args.highlightedMember);
        return;
      }
      this.highlightedMember = null;
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <div class="chat-message-creator__members-container">
        <div class="chat-message-creator__members">
          {{icon "search"}}
  
          {{#each @members as |member|}}
            <Member
              @member={{member}}
              @onSelect={{@onSelectMember}}
              @highlighted={{eq member @highlightedMember}}
            />
          {{/each}}
  
          <Input
            placeholder={{this.addMoreMembersLabel}}
            class="chat-message-creator__members-input"
            @value={{@filter}}
            autofocus={{true}}
            {{on "input" this.onFilter}}
            {{on "keydown" this.handleKeypress}}
            {{didInsert this.registerFocusFilterAction}}
          />
        </div>
      </div>
    
  */
  {
    "id": "bOXmzmPP",
    "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"chat-message-creator__members-container\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"chat-message-creator__members\"],[12],[1,\"\\n        \"],[1,[28,[32,0],[\"search\"],null]],[1,\"\\n\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,1]],null]],null],null,[[[1,\"          \"],[8,[32,1],null,[[\"@member\",\"@onSelect\",\"@highlighted\"],[[30,2],[30,3],[28,[32,2],[[30,2],[30,4]],null]]],null],[1,\"\\n\"]],[2]],null],[1,\"\\n        \"],[8,[32,3],[[16,\"placeholder\",[30,0,[\"addMoreMembersLabel\"]]],[24,0,\"chat-message-creator__members-input\"],[16,\"autofocus\",true],[4,[32,4],[\"input\",[30,0,[\"onFilter\"]]],null],[4,[32,4],[\"keydown\",[30,0,[\"handleKeypress\"]]],null],[4,[32,5],[[30,0,[\"registerFocusFilterAction\"]]],null]],[[\"@value\"],[[30,5]]],null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@members\",\"member\",\"@onSelectMember\",\"@highlightedMember\",\"@filter\"],false,[\"each\",\"-track-array\"]]",
    "moduleName": "/app/code/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/message-creator/members.js",
    "scope": () => [_dIcon.default, _member.default, _eq.default, _component2.Input, _modifier.on, _didInsert.default],
    "isStrictMode": true
  }), _class2), _class2), (_applyDecoratedDescriptor(_class.prototype, "onFilter", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onFilter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "registerFocusFilterAction", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "registerFocusFilterAction"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleKeypress", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleKeypress"), _class.prototype)), _class);
});